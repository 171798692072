<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Consider the processes described in the following table and indicate the appropriate value
        for heat (q), work (w), and change in internal energy (DU) of the <b>system</b>. Be sure to
        account for the proper sign of each value. Also, determine whether each process is
        exothermic (exo) or endothermic (endo).
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Process</th>
            <th style="font-size: 15px">q (kJ)</th>
            <th style="font-size: 15px">w (kJ)</th>
            <th style="font-size: 15px">DU (kJ)</th>
            <th style="font-size: 15px">Thermicity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="py-3">
              a) A system absorbs
              <number-value :value="q1" unit="\text{kJ}" />
              of heat from the surroundings and does
              <number-value :value="w1" unit="\text{kJ}" />
              of work on the surroundings.
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.q1inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.w1inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.DU1inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <v-select
                v-model="inputs.therm1inp"
                class="d-sm-inline-block"
                dense
                :items="thermicityOptions"
                placeholder="Select"
              />
            </td>
          </tr>

          <tr>
            <td class="py-3">
              b) A system releases
              <number-value :value="q2" unit="\text{kJ}" />
              of heat while the surroundings does
              <number-value :value="w2" unit="\text{kJ}" />
              of work on it.
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.q2inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.w2inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.DU2inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <v-select
                v-model="inputs.therm2inp"
                class="d-sm-inline-block"
                dense
                :items="thermicityOptions"
                placeholder="Select"
              />
            </td>
          </tr>

          <tr>
            <td class="py-3">
              c) A balloon is heated by adding
              <number-value :value="q3" unit="\text{kJ}" />
              of heat. It expands, doing
              <number-value :value="w3" unit="\text{kJ}" />
              of work on the atmosphere.
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.q3inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.w3inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.DU3inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <v-select
                v-model="inputs.therm3inp"
                class="d-sm-inline-block"
                dense
                :items="thermicityOptions"
                placeholder="Select"
              />
            </td>
          </tr>

          <tr>
            <td class="py-3">
              d) A
              <number-value :value="mass" unit="\text{g}" />
              sample of iron metal is cooled from
              <latex-number number="100" unit="^\circ\text{C}" />
              to
              <number-value :value="temp" unit="^\circ\text{C,}" />
              thereby losing approximately
              <latex-number :number="q4.toFixed(2)" unit="\text{kJ}" />
              of heat.
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.q4inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.w4inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <calculation-input v-model="inputs.DU4inp" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <v-select
                v-model="inputs.therm4inp"
                class="d-sm-inline-block"
                dense
                :items="thermicityOptions"
                placeholder="Select"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import NumberValue from '@/tasks/components/NumberValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question391',
  components: {
    CalculationInput,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        q1inp: null,
        w1inp: null,
        DU1inp: null,
        therm1inp: null,
        q2inp: null,
        w2inp: null,
        DU2inp: null,
        therm2inp: null,
        q3inp: null,
        w3inp: null,
        DU3inp: null,
        therm3inp: null,
        q4inp: null,
        w4inp: null,
        DU4inp: null,
        therm4inp: null,
      },
      thermicityOptions: ['Exo', 'Endo'],
    };
  },
  computed: {
    q1() {
      return this.taskState.getValueBySymbol('q1').content;
    },
    q2() {
      return this.taskState.getValueBySymbol('q2').content;
    },
    q3() {
      return this.taskState.getValueBySymbol('q3').content;
    },
    w1() {
      return this.taskState.getValueBySymbol('w1').content;
    },
    w2() {
      return this.taskState.getValueBySymbol('w2').content;
    },
    w3() {
      return this.taskState.getValueBySymbol('w3').content;
    },
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    q4() {
      return (0.449 / 1000) * this.mass.toFloat() * (100 - this.temp.toFloat());
    },
  },
};
</script>
